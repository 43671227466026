import styled from 'styled-components'

export const Wrapper = styled.div `
        .error-template {
            padding: 40px 15px;text-align: center;
        }

        .error-actions {
            margin-top:15px;margin-bottom:15px;
        }
        .error-actions .btn { 
            margin-right:10px; 
        }
`;